import {createStore as reduxCreateStore} from "redux"

const key = "app_state";
const initialState = {selection: []};

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return {
                selection: [...state.selection.filter(item => item && item.id !== action.vehicle.id), action.vehicle]
            };
        case "REMOVE":
            return {selection: state.selection.filter(item => item && item.id !== action.id)};
        default:
            return state;
    }
}

const persistReducer = (state, action) => {
    const newState = reducer(state, action);

    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(newState));
    }

    return newState;
}

const createStore = () => reduxCreateStore(persistReducer, (typeof window !== 'undefined') ? JSON.parse(window.localStorage.getItem(key)) || initialState : initialState);
export default createStore
